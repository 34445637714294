document.addEventListener("DOMContentLoaded", function () {
    let heroEle = document.querySelectorAll(".logo-holder .fade,#desk-menu .fade, .hero-section .fade");
    heroEle.forEach((item, index) => {
        if (item.dataset.animation) {
            item.classList.add(item.dataset.animation);
        }
        else {
            item.classList.add('fade-up');
        }
        item.style.transitionDelay = (0.1 * (index + 1)) + 's';
    })
    let about = document.querySelector('#about .section-heading');
    if (elementInViewport(about)) {
        triggerFade(document.querySelectorAll("#about .fade"))
    }
    let rooms = document.querySelector('#rooms .section-heading');
    if (elementInViewport(rooms)) {
        triggerFade(document.querySelectorAll("#rooms .fade"))
    }
    let location = document.querySelector('#location .section-heading');
    if (elementInViewport(location)) {
        triggerFade(document.querySelectorAll("#location .fade"))
    }
    let contact = document.querySelector('#contact .section-heading');
    if (elementInViewport(contact)) {
        triggerFade(document.querySelectorAll("#contact .fade"))
    }
    let floaterMenu = document.querySelectorAll(".floater")[0];
    if (floaterMenu) {
        floaterMenu.style.transitionDelay = '1.5s';
        floaterMenu.style.opacity = 1;
    }
    let toggle = document.getElementById('toggle');
    toggle.addEventListener('click', () => {
        let overlay = document.querySelector('.mobile-menu');
        overlay.classList.add('show');
        document.querySelector('.main').classList.add('blur');
        document.addEventListener('click', handleClick);
        let eles = document.querySelectorAll("#mobile-menu .fade");
        eles.forEach((item, index) => {
            if (item.dataset.animation) {
                item.classList.add(item.dataset.animation);
            }
            else {
                item.classList.add('fade-up');
            }
            item.style.transitionDelay = (0.1 * (index + 1)) + 's';
        })
    })
});


const throttle = (fn, limit) => {
    let flag = true;
    return function () {
        let context = this;
        let args = arguments;
        if (flag) {
            fn.apply(context, args);
            flag = false;
            setTimeout(() => {
                flag = true;
            }, limit);
        }
    }
}

let scrollT = 0;
window.addEventListener("scroll", throttle(handleScroll, 100));
function handleScroll(e) {
    scrollT = window.scrollY;
    let about = document.querySelector('#about .section-heading');
    if (elementInViewport(about)) {
        triggerFade(document.querySelectorAll("#about .fade"))
    }
    let rooms = document.querySelector('#rooms .section-heading');
    if (elementInViewport(rooms)) {
        triggerFade(document.querySelectorAll("#rooms .fade"))
    }
    let location = document.querySelector('#location .section-heading');
    if (elementInViewport(location)) {
        triggerFade(document.querySelectorAll("#location .fade"))
    }
    let contact = document.querySelector('#contact .section-heading');
    if (elementInViewport(contact)) {
        triggerFade(document.querySelectorAll("#contact .fade"))
    }
}

function elementInViewport(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top < (window.pageYOffset + window.innerHeight - 100) &&
        left < (window.pageXOffset + window.innerWidth) &&
        (top + height) > window.pageYOffset &&
        (left + width) > window.pageXOffset
    );
}

function triggerFade(ele) {
    ele.forEach((item, index) => {
        if (item.dataset.animation) {
            item.classList.add(item.dataset.animation);
        }
        else {
            item.classList.add('fade-up');
        }
        item.style.transitionDelay = (0.1 * (index + 1)) + 's';
    })

}
function handleClick(e) {
    if (((e.target.className === 'overlay' || (e.target.classList.contains('toggle') || e.target.classList.contains('nav-items'))) && !(e.target.id === 'toggle'))) {
        let overlay = document.querySelector('.mobile-menu');
        overlay.classList.remove('show');
        document.querySelector('.main').classList.remove('blur');
        document.removeEventListener('click', handleClick);
    } else {
    }
}